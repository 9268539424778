<!--================================
=            BASEHEADER            =
=================================-->
<template>
  <div class="base-header d-flex flex-row align-center">
    <v-row  justify="space-between" :align="alignment">
      <v-col class="col-8 ">
        <slot name="page-header"></slot>
      </v-col>
      <v-col class="col-4">
        <slot name="page-search"></slot>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "base-header",
  props: {
    alignment: {
      type: String,
      default: 'center'
    }
  },
  data() {
    return {
      title: "Dashboard",
      breadcrumbs: [],
    };
  },
};
</script>
<style scoped>
.base-header {
  min-height: 90px;
}

.page-header {
  font-size: 22px;
  font-weight: bolder;
  color: "primary";

}
</style>
<!--====  End of BASEHEADER  ====-->
